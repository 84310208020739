/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Preview from './Preview'
import SortPanel from 'src/components/SortPanel'
import MissingContent from 'src/components/MissingContent'
import classNames from 'classnames'
import { handleSortByDuration, handleSortByDate } from 'src/utils'

import styles from './AudiosList.module.scss'

class AudiosList extends Component {
  state = {
    audios: handleSortByDate(
      this.props.audios
        .filter((item) => item.node.title !== '_' && item.node.duration !== null)
        .map((i) => (i = { ...i.node })),
    ),
  }

  handleSort = (ev) => {
    let activeOption = ev.target.value
    const { audios } = this.state
    switch (activeOption) {
      case 'duration':
        this.setState({ audios: handleSortByDuration(audios) })
        break
      case 'date':
        this.setState({ audios: handleSortByDate(audios) })
      default:
    }
  }

  render() {
    const { style, locale, intl, hideSortPanel, addMarginTop } = this.props
    const { audios } = this.state

    const options = [
      { type: 'date', text: intl.formatMessage({ id: 'home.date' }) },
      { type: 'duration', text: intl.formatMessage({ id: 'home.duration' }) },
    ]

    const content = (
      <>
        <SortPanel
          title={intl.formatMessage({ id: 'home.Allpodcasts' })}
          options={options}
          sortTitle={intl.formatMessage({ id: 'home.Sortby' })}
          onGetOption={(ev) => this.handleSort(ev)}
          hideSortPanel={hideSortPanel}
        />
        <section className={classNames(styles.root, addMarginTop && styles.addMarginTop)} style={style}>
          <div className={styles.inner}>
            <div className={styles.wrap}>
              {audios && audios.map((item, index) => <Preview key={item.title + index} {...item} locale={locale} />)}
            </div>
          </div>
        </section>
      </>
    )

    return audios && locale !== 'en' ? (
      content
    ) : (
      <MissingContent locale={locale} title={intl.formatMessage({ id: 'home.missingPodcasts' })} />
    )
  }
}

AudiosList.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string.isRequired,
}

export default injectIntl(AudiosList)
