import React, { Component } from 'react'
import styles from './MissingContent.module.scss'

class MissingContent extends Component {
  render() {
    const { title } = this.props

    return (
      <div className={styles.content}>
        <p>{title}</p>
      </div>
    )
  }
}

export default MissingContent
