import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Header from 'src/components/Header'
import AudiosList from '../components/AudiosList'
import Footer from 'src/components/Footer'
import PageHelmet from 'src/components/PageHelmet'
import Subscribe from 'src/components/Subscribe'
import withLayout from 'src/components/withLayout'
import { injectIntl } from 'react-intl'

class Podcasts extends Component {
  render() {
    const {
      data: {
        podcasts: { edges: audios },
        subscribe,
      },
      locale,
      changeLocale,
      intl,
    } = this.props

    return (
      <div>
        <PageHelmet title={intl.formatMessage({ id: 'home.podcastsButton' })} locale={locale} defer={false} />
        <Header full={true} locale={locale} changeLocale={changeLocale} />
        <AudiosList audios={audios} locale={locale} />
        <Subscribe style={{ marginTop: '15px' }} locale={locale} subscribe={subscribe} />
        <Footer locale={locale} />
      </div>
    )
  }
}

export const query = graphql`
  query Podcasts($locale: String!) {
    podcasts: allDatoCmsPodcast(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          title
          duration
          publishDate
          guest
          cover {
            url
            id
            fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress, format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          podcast {
            url
          }
          slug
        }
      }
    }
    subscribe: file(relativePath: { eq: "subscribe-illustration.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'podcasts',
}

export default withLayout(customProps)(injectIntl(Podcasts))
