import React, { Component } from 'react'
import classNames from 'classnames'
import PlayButton from 'src/components/svg/PlayButton'
import { PodcastContext } from 'src/components/PodcastLayout'
import styles from './Preview.module.scss'
import { getBaseUrl } from 'src/utils'
import PropTypes from 'prop-types'
import SocialShare from 'src/components/SocialShare'

class Preview extends Component {
  handleClickPlay = () => {
    this.setState({ isPlaying: !this.state.isPlaying })
  }

  render() {
    const {
      className,
      locale,
      title,
      cover,
      cover: { url: coverImage },
      podcast: { url: podcastFile },
      guest,
      duration,
      slug,
    } = this.props

    return (
      <PodcastContext.Consumer>
        {(contextValue) => {
          const { onChangeData, isPlaying, currentUrl, onChangePlay } = contextValue
          return (
            <>
              {title && (
                <article
                  className={classNames(className, styles.root)}
                  onClick={() => onChangeData(podcastFile, title, guest, cover, slug)}
                >
                  <figure
                    className={classNames(className, styles.preview, {
                      [styles.active]: isPlaying && podcastFile === currentUrl,
                    })}
                    onClick={onChangePlay}
                  >
                    <img src={coverImage} alt={title} />
                    <button className={styles.playButton}>
                      <PlayButton height="56px" width="56px" aria-label="play" />
                    </button>
                    <SocialShare
                      isWhiteFixed={true}
                      className={styles.share}
                      title={title}
                      url={`${getBaseUrl()}${locale}/podcast/${slug}`}
                      locale={this.props.locale}
                    />
                    <p className={styles.audioDuration}>{duration}</p>
                  </figure>
                  <p className={styles.title}>{title}</p>
                </article>
              )}
            </>
          )
        }}
      </PodcastContext.Consumer>
    )
  }
}

Preview.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string,
  node: PropTypes.shape({
    title: PropTypes.string,
    cover: PropTypes.object,
    podcast: PropTypes.object,
    guest: PropTypes.string,
    duration: PropTypes.string,
    slug: PropTypes.string,
  }),
}

export default Preview
